import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IColors } from '@app/core/interfaces/color.interface';

@Component({
  selector: 'ag1-badge-base',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class Ag1BadgeBaseComponent {
  /**
   * @param label What you'd like to display in the badge
   * @example 'Delivered'
   * */
  @Input() label: string;
  /**
   * @param color The color of the badge
   * @example 'green'
   * */
  @Input() color: IColors = 'slate';
  /**
   * @param colorClassOverride If you want to override the default color classes, you can pass in a string here
   * @example 'is-line-item-accepted'
   */
  @Input() colorClassOverride: string;

  constructor() {}
}
