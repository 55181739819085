import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostListener,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { NewOrderStore } from '../../account/new-order/stores/new-order.store';
import { ToastService } from '@app/core/services/toast/toast.service';
import { Subscription, Observable, zip } from 'rxjs';
import { noop } from '@app/core/util/noop';
import { VersionService } from '@app/core/services/version/version.service';
import { UserHelper } from '@app/core/helpers/user.helper';
import { PermissionService } from '@app/core/services/permission.service';
import { ConfirmModalComponent } from '@app/core/components/confirm-modal/confirm-modal.component';

import {
  UserFeedItem,
  UpdateUserFeedItemsGQL,
  UpdateUserFeedItemGQL,
  NewUserFeedItemGQL,
  UserFeedItemsConnectionGQL,
  UserFeedItemEdge,
  PageInfo,
  UserFeedUnseenCountGQL,
  User,
  Company,
  UserGQL,
  PermissionEnum,
} from '@app/generated/graphql';
import { clone } from '@app/core/util/clone';
import { NavigationType } from './nav.interface';
import { NavService } from '../../core/services/nav.service';
import { ModalService } from '@app/core/services/modal/modal.service';
import { IsoWeekService } from '@app/core/services/isoWeek.service';

@Component({
  selector: 'ag-nav',
  templateUrl: './nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit, OnDestroy {
  isAgrigateUser: boolean;
  hasUnsavedOrder: boolean;
  currentUser: User | null = null;
  isLoading = true;
  uiVersion$: Observable<number>;

  @Input()
  isExistingUser: any;

  @Input()
  myCompany: Company;

  @ViewChild('userFeedNavbarLink')
  userFeedNavbarLinkRef: ElementRef<HTMLElement>;

  @ViewChild('userFeedContainer')
  userFeedContainerRef: ElementRef<HTMLElement>;

  showSettings = false;
  showUserFeed = false;
  loadingUserFeed = false;
  userFeedEdges: UserFeedItemEdge[] = [];
  userFeedPageInfo: PageInfo;
  userFeedItemSubscription: Subscription;
  userFeedUnseenItemCount: number;
  permissionEnum = PermissionEnum;

  mainPageRoute: string = '';

  mainNavigation: NavigationType[] = [
    {
      route: '/account/dashboard',
      label: 'Dashboard',
      icon: 'assets/icons/general/map.svg',
      permission: PermissionEnum.PagesDashboard,
    },
    {
      label: 'Plan',
      icon: 'assets/icons/general/box-empty.svg',
      permissions: [
        PermissionEnum.PagesEstimations,
        PermissionEnum.PagesNewOrder,
        PermissionEnum.PagesOrders,
        PermissionEnum.PagesPlanning,
        PermissionEnum.PagesPackInstructions,
        PermissionEnum.PagesPallets,
      ],
      items: [
        {
          route: '/account/estimations',
          label: 'Estimations',
          permission: PermissionEnum.PagesEstimations,
        },
        {
          route: '/account/new-order',
          label: 'New Order',
          permission: PermissionEnum.PagesNewOrder,
        },
        {
          route: '/account/orders',
          label: 'Orders',
          permission: PermissionEnum.PagesOrders,
        },
        {
          route: '/account/planning',
          label: 'Planning',
          permission: PermissionEnum.PagesPlanning,
        },
        {
          route: '/account/pack-instructions',
          label: 'Pack Instructions',
          permission: PermissionEnum.PagesPackInstructions,
        },
        {
          route: '/account/pallets',
          label: 'Pallets',
          permission: PermissionEnum.PagesPallets,
        },
      ],
    },
    {
      route: '/account/stock',
      label: 'Stock',
      icon: './assets/icons/dashboard/cards/pallet.svg',
      permission: PermissionEnum.PagesStock,
    },
    {
      label: 'Move',
      icon: 'assets/icons/general/location-movement.svg',
      permissions: [
        PermissionEnum.PagesLoadOutInstructions,
        PermissionEnum.PagesCommercialInvoices,
        PermissionEnum.PagesShipments,
        PermissionEnum.PagesAirFreightShipments,
      ],
      items: [
        {
          route: '/account/load-out-instructions',
          label: 'Load Out Instructions',
          permission: PermissionEnum.PagesLoadOutInstructions,
        },
        {
          route: '/account/commercial-invoices',
          label: 'Commercial Invoices',
          permission: PermissionEnum.PagesCommercialInvoices,
        },
        {
          route: '/account/shipments',
          label: 'Ocean Shipments',
          permission: PermissionEnum.PagesShipments,
        },
        {
          route: '/account/air-freight-shipments',
          label: 'Air Freight Shipments',
          permission: PermissionEnum.PagesAirFreightShipments,
        },
      ],
    },
    {
      label: 'Quality',
      icon: '/assets/icons/general/shield-check.svg',
      permissions: [PermissionEnum.PagesClaims],
      items: [
        {
          label: 'Arrival QC',
          route: '/account/arrival-qc',
          permission: PermissionEnum.PagesClaims,
          state: 'beta',
        },
      ],
    },
    {
      label: 'Transact',
      icon: 'assets/icons/general/bank-card.svg',
      permissions: [
        PermissionEnum.PagesCashFlow,
        PermissionEnum.PagesCommercialTerms,
        PermissionEnum.PagesFinancePipeline,
        PermissionEnum.PagesPayments,
        PermissionEnum.PagesFinanceIndex,
        PermissionEnum.PagesFinanceRuleManagement,
        PermissionEnum.PagesDefaultCosts,
      ],
      items: [
        {
          label: 'Finance Overview',
          route: '/account/finance/overview',
          permission: PermissionEnum.PagesFinanceIndex,
        },
        {
          label: 'Cash Flow',
          route: '/account/cash-flow',
          permission: PermissionEnum.PagesCashFlow,
        },
        {
          label: 'Customer Invoices',
          route: '/account/financial-invoices',
          permission: PermissionEnum.PagesCustomerInvoices,
        },
        {
          label: 'Vendor Invoices',
          route: '/account/vendor-invoices',
          permission: PermissionEnum.PagesVendorInvoices,
        },
        {
          label: 'Default Costs',
          route: '/account/default-costs',
          permission: PermissionEnum.PagesDefaultCosts,
        },
        {
          label: 'Payments',
          route: '/account/payments',
          permission: PermissionEnum.PagesPayments,
          state: 'beta',
        },
        {
          label: 'Reports',
          route: '/account/finance/reports',
          permission: PermissionEnum.PagesCashFlow, // TODO: add permission
        },
        {
          label: 'Commercial Terms',
          route: '/account/commercial-terms',
          permission: PermissionEnum.PagesCommercialTerms,
        },
        {
          label: 'Finance Rules',
          route: '/account/finance-rules',
          permission: PermissionEnum.PagesFinanceRuleManagement,
        },
        {
          label: 'Finance Pipeline',
          route: '/account/finance/pipeline',
          permission: PermissionEnum.PagesFinancePipeline,
        },
      ],
    },
    {
      label: 'Insights',
      icon: 'assets/icons/general/chart.svg',
      route: '/account/insights',
      permission: PermissionEnum.PagesInsights,
    },
    {
      label: 'Files',
      icon: 'assets/icons/general/folder.svg',
      permissions: [
        PermissionEnum.PagesAnalysisDownloads,
        PermissionEnum.PagesDataExports,
      ],
      items: [
        {
          label: 'Analysis Downloads',
          route: '/account/analysis-downloads',
          permission: PermissionEnum.PagesAnalysisDownloads,
        },
        {
          label: 'Data Exports',
          route: '/account/data-exports',
          permission: PermissionEnum.PagesDataExports,
        },
      ],
    },
  ];

  fixedNavigation: NavigationType[] = [
    {
      label: 'Settings',
      route: '/account/settings',
      permission: PermissionEnum.PagesSettings,
      icon: 'assets/icons/general/settings.svg',
    },
  ];

  settingsNavigation: NavigationType[] = [
    {
      label: 'My Account',
      route: '/account/settings',
      permission: PermissionEnum.PagesSettings,
      icon: 'assets/icons/general/user.svg',
    },
    {
      label: 'Company',
      route: '/account/company',
      permission: PermissionEnum.PagesCompany,
      icon: 'assets/icons/general/users.svg',
    },
    {
      label: 'Masterfiles',
      route: '/account/masterfiles',
      permission: PermissionEnum.PagesMasterfiles,
      icon: 'assets/icons/general/settings-masterfiles.svg',
    },
    {
      label: 'Trade Partners',
      route: '/account/partners',
      permission: PermissionEnum.PagesPartners,
      icon: 'assets/icons/general/partners.svg',
    },
  ];

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private newOrderStore: NewOrderStore,
    private toastService: ToastService,
    private updateUserFeedItemsGQL: UpdateUserFeedItemsGQL,
    private updateUserFeedItemGQL: UpdateUserFeedItemGQL,
    private newUserFeedItemGQL: NewUserFeedItemGQL,
    private userFeedItemsConnectionGQL: UserFeedItemsConnectionGQL,
    private userFeedUnseenCountGql: UserFeedUnseenCountGQL,
    private versionService: VersionService,
    private userGql: UserGQL,
    private permissionService: PermissionService,
    public service: NavService,
    private modalService: ModalService,
    public isoWeekService: IsoWeekService
  ) {}

  get unsavedNewOrder(): boolean {
    return this.hasUnsavedOrder;
  }

  ngOnInit() {
    this.isLoading = true;
    this.subscribeUnsavedNewOrder();
    this.isSettingsPage();
    this.userGql.fetch().subscribe(
      (z) => {
        this.currentUser = z.data.myUser;
        this.isLoading = false;
        this.isAgrigateUser = UserHelper.isAgrigateUser(this.currentUser);
      },
      (err) => {
        this.isLoading = false;
        this.router.navigate(['account', '500'], { replaceUrl: true });
        throw err;
      }
    );

    this.uiVersion$ = this.versionService.uiVersion();

    this.userFeedSubscriptions();
  }

  async userFeedSubscriptions() {
    if (
      (await this.permissionService.allow(
        PermissionEnum.ViewOrderViewComments
      )) &&
      (await this.permissionService.allow(PermissionEnum.PagesViewOrder))
    ) {
      this.userFeedItemsConnectionGQL.fetch({ first: 5 }).subscribe(
        (res) => {
          this.userFeedEdges = clone(res.data.userFeedConnection.edges);
          this.userFeedPageInfo = res.data.userFeedConnection.pageInfo;
        },
        (err) => {
          throw err;
        }
      );

      this.userFeedUnseenCountGql.fetch().subscribe(
        (res) => {
          this.userFeedUnseenItemCount = res.data.userFeedUnseenCount;
        },
        (err) => {
          throw err;
        }
      );

      this.userFeedItemSubscription = this.newUserFeedItemGQL
        .subscribe()
        .subscribe(() => {
          this.userFeedItemsConnectionGQL
            .fetch({ first: 1 })
            .subscribe((gqlResponse) => {
              this.userFeedEdges = [
                ...clone(gqlResponse.data.userFeedConnection.edges),
                ...this.userFeedEdges,
              ];
              if (!this.showUserFeed) {
                this.userFeedUnseenItemCount++;
              }
            });
        });
    }
  }

  // Hide the user feed if we click outside it
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    if (this.userFeedContainerRef && this.userFeedNavbarLinkRef) {
      if (
        !this.userFeedNavbarLinkRef.nativeElement.contains(event.target) &&
        !this.userFeedContainerRef.nativeElement.contains(event.target)
      ) {
        if (this.showUserFeed) {
          this.toggleUserFeed(false);
        }
      }
    }
  }

  onUserFeedScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      this.fetchMoreUserFeedItems();
    }
  }

  markUserFeedItemAsRead(userFeedItem: UserFeedItem): void {
    this.updateUserFeedItemGQL
      .mutate({
        attributes: {
          id: userFeedItem.id,
          read: true,
          seen: true,
        },
      })
      .subscribe(
        (_) => {
          if (!userFeedItem.seen) {
            this.userFeedUnseenItemCount--;
            userFeedItem.seen = true;
          }
          userFeedItem.read = true;
        },
        (err) => {
          throw err;
        }
      );
  }

  navigateUserFeedItemRoute(userFeedItem: UserFeedItem): void {
    if (userFeedItem.hasOwnProperty('notifiable')) {
      switch (userFeedItem.notifiable.__typename) {
        case 'Comment':
          switch (userFeedItem.notifiable.commentable.__typename) {
            case 'Order':
              this.router.navigate([
                '/account/order/',
                userFeedItem.notifiable.commentable.id,
              ]);
              break;
            default:
              throw new Error(
                `Unable to navigte from user feed item for commentable ${userFeedItem.notifiable.commentable.__typename}`
              );
          }
          break;

        default:
          throw new Error(
            `Unable to navigte from user feed item for notifiable ${userFeedItem.notifiable.__typename}`
          );
      }
    }
  }

  toggleUserFeed(showUserFeed?: boolean): void {
    if (showUserFeed === undefined) {
      this.showUserFeed = !this.showUserFeed;
    } else {
      this.showUserFeed = showUserFeed;
    }

    if (!this.showUserFeed) {
      this.userFeedContainerRef.nativeElement.scroll(0, 0);
      this.userFeedEdges.forEach((edge) => {
        if (!edge.node.seen) {
          this.userFeedUnseenItemCount--;
          edge.node.seen = true;
        }
      });
    } else {
      this.markAllUserFeedItemsAsSeen();
    }
  }

  onUserFeedItemClick(userFeedItem: UserFeedItem): void {
    this.markUserFeedItemAsRead(userFeedItem);
    this.navigateUserFeedItemRoute(userFeedItem);
    this.toggleUserFeed(false);
  }

  fetchMoreUserFeedItems() {
    if (this.userFeedPageInfo.hasNextPage && !this.loadingUserFeed) {
      this.loadingUserFeed = true;
      this.userFeedItemsConnectionGQL
        .fetch({
          first: 5,
          after: this.userFeedEdges[this.userFeedEdges.length - 1].cursor,
        })
        .subscribe(
          (res) => {
            this.userFeedEdges = [
              ...this.userFeedEdges,
              ...clone(res.data.userFeedConnection.edges),
            ];
            this.userFeedPageInfo = res.data.userFeedConnection.pageInfo;
            this.markAllUserFeedItemsAsSeen();
            this.loadingUserFeed = false;
          },
          (err) => {
            throw err;
          }
        );
    }
  }

  markAllUserFeedItemsAsSeen(): void {
    const unseenAttributes = this.userFeedEdges
      .filter((edge) => !edge.node.seen)
      .map((edge) => ({
        id: edge.node.id,
        seen: true,
      }));

    if (unseenAttributes.length > 0) {
      this.updateUserFeedItemsGQL
        .mutate({
          attributes: unseenAttributes,
        })
        .subscribe(noop, (err) => {
          throw err;
        });
    }
  }

  navigate(view): void {
    this.router.navigate(view);
  }

  logout(): ConfirmModalComponent {
    const modalInstance = this.modalService.showConfirm({
      title: 'Logout?',
      content: 'Are you sure you want to logout of the AgrigateOne platform?',
      confirmBtnText: 'Logout',
      confirmBtnClass: 'is-danger',
      canCancel: true,
      onConfirm: () => {
        this.authService.logout();
      },
    });
    return modalInstance;
  }

  private subscribeUnsavedNewOrder(): void {
    this.newOrderStore.isUnsaved.subscribe((isUnsaved) => {
      this.hasUnsavedOrder = isUnsaved;
    });
  }

  ngOnDestroy(): void {
    if (this.userFeedItemSubscription) {
      this.userFeedItemSubscription.unsubscribe();
    }
  }

  setMainPageRoute(): void {
    this.mainPageRoute = this.router.url.split('?')[0];
  }

  getDefaultPageRoute(): string {
    return this.humanizeRouteSegment(
      this.currentUser?.permissions?.landingPage
    );
  }

  navigationRouteHumanized(): string {
    return (
      this.humanizeRouteSegment(this.mainPageRoute) ||
      this.getDefaultPageRoute()
    );
  }

  backToPageNavigation(): void {
    this.navigate([this.mainPageRoute]);
  }

  humanizeRouteSegment(segment: string): string {
    // extract component name from route
    // '/account/commercial-invoices' => 'commercial-invoices'
    return segment
      ?.split('/')
      ?.slice(-1)[0]
      ?.replace(/pages/g, '')
      ?.replace(/_/g, ' ')
      ?.replace(/-/g, ' ')
      ?.replace(/\b\w/g, (char) => char.toUpperCase())
      ?.trim();
  }

  isSettingsPage(): void {
    this.showSettings = this.settingsNavigation.some((nav) =>
      'route' in nav
        ? nav.route === this.router.url
        : nav.items.some((item) => item.route === this.router.url)
    );
  }
}
