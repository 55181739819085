
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CommentableUnion": [
      "LineItem",
      "Order"
    ],
    "ElasticHitSourceUnion": [
      "DashboardCartonGrouping",
      "DashboardContainer",
      "DashboardLocation"
    ],
    "MapPointLocatableUnion": [
      "FoodBusinessOperator",
      "SeaPort",
      "Vessel"
    ],
    "MasterfileFarmVariety": [
      "FarmVariety",
      "FarmVarietyRequest"
    ],
    "MasterfileFoodBusinessOperator": [
      "FoodBusinessOperator",
      "FoodBusinessOperatorRequest"
    ],
    "NotifiableUnion": [
      "Comment"
    ]
  }
};
      export default result;
    