import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ag1-title',
  templateUrl: './ag1-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class Ag1TitleComponent implements OnInit, OnDestroy {
  @Input()
  label = 'Title';

  @Input()
  state: 'new' | 'beta' | 'alpha' | undefined = undefined;

  agrigateOne: string = 'AgrigateOne';

  constructor(private titleService: Title) {}

  ngOnInit(): void {
    if (this.label === 'Title' || !this.label) {
      this.titleService.setTitle(this.agrigateOne);
      return;
    }

    this.titleService.setTitle(`${this.agrigateOne} | ${this.label}`);
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(this.agrigateOne);
  }
}
