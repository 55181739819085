import {
  ConnectionPositionPair,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  TemplateRef,
  OnDestroy,
} from '@angular/core';

@Component({
  selector: 'ag1-menu',
  templateUrl: './ag1-menu.component.html',
})
export class Ag1MenuComponent implements OnDestroy {
  @Input() isModal: boolean = false;
  @Input() preferedPosition: ConnectionPositionPair[] = [
    {
      // bottom-left
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      // bottom-right
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
    {
      // top-right
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
    },
    {
      // top-left
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
    // ... (keep existing positions)
  ];
  @Input() disabled: boolean = false;

  @Output() menuClosed: EventEmitter<any> = new EventEmitter();
  @Output() menuIsOpen: EventEmitter<any> = new EventEmitter();

  @ContentChild('customTrigger', { static: false })
  customTriggerRef?: TemplateRef<any>;
  @ContentChild('customOverlay', { static: false })
  customOverlayRef?: TemplateRef<any>;

  // state is handled by this component
  // but you can close the menu from the outside by grabbing the
  // menuComponent and calling closeMenu() on it
  // eg.
  // @ViewChild(Ag1MenuComponent)
  // ag1Menu: Ag1MenuComponent;
  // this.ag1Menu.closeMenu(); // close the menu
  @Input() isOpen: boolean = false;

  private overlayRef: OverlayRef | null = null;

  constructor(private overlay: Overlay) {}

  ngOnDestroy() {
    this.closeMenu();
  }

  openMenu(): void {
    if (this.disabled || this.isOpen) return;

    if (this.isModal) {
      this.openAsModal();
    } else {
      this.isOpen = true;
      this.menuIsOpen.emit();
    }
  }

  closeMenu(): void {
    if (this.disabled) return;

    if (this.isModal && this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    } else {
      this.isOpen = false;
    }
    this.menuClosed.emit();
  }

  toggleMenu(): void {
    if (this.isOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  private openAsModal(): void {
    const positionStrategy = this.overlay
      .position()
      .global()
      .bottom('16px')
      .right('16px');

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'bg-transparent',
    });

    const portal = new ComponentPortal(Ag1ModalContentComponent);
    const componentRef = this.overlayRef.attach(portal);
    componentRef.instance.customOverlayRef = this.customOverlayRef;

    this.overlayRef.backdropClick().subscribe(() => this.closeMenu());
  }
}

@Component({
  template: `
    <div class="bg-white rounded-lg shadow-xl my-1">
      <ng-container *ngTemplateOutlet="customOverlayRef"></ng-container>
    </div>
  `,
})
export class Ag1ModalContentComponent {
  customOverlayRef?: TemplateRef<any>;
}
