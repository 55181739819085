import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatementGenerationService {
  private generatingFilesCount = 0;
  private isGeneratingSubject = new BehaviorSubject<boolean>(false);
  isGenerating$ = this.isGeneratingSubject.asObservable();

  startGeneration(): void {
    this.generatingFilesCount++;
    this.updateGeneratingState();
  }

  completeGeneration(): void {
    if (this.generatingFilesCount > 0) {
      this.generatingFilesCount--;
      this.updateGeneratingState();
    }
  }

  private updateGeneratingState(): void {
    const isGenerating = this.generatingFilesCount > 0;
    if (this.isGeneratingSubject.value !== isGenerating) {
      this.isGeneratingSubject.next(isGenerating);
    }
  }
}
