<div class="flex items-center gap-3 w-fit">
  <h1
    class="
      !text-slate-800 !text-xl
      !font-semibold
      !select-none
      !whitespace-nowrap
      !capitalize
    "
  >
    {{ label }}
  </h1>

  <ng-container *ngIf="state">
    <ng-container
      [ngTemplateOutlet]="BadgeTemplate"
      [ngTemplateOutletContext]="{
        state: state
      }"
    ></ng-container>
  </ng-container>
</div>

<ng-template #BadgeTemplate let-state="state">
  <ng-container [ngSwitch]="state">
    <ag1-badge-solid
      *ngSwitchCase="'new'"
      label="NEW"
      color="french-blue"
    ></ag1-badge-solid>
    <ag1-badge-solid
      *ngSwitchCase="'beta'"
      label="BETA"
      color="slate"
    ></ag1-badge-solid>
    <ag1-badge-solid
      *ngSwitchCase="'alpha'"
      label="ALPHA"
      color="slate"
    ></ag1-badge-solid>
  </ng-container>
</ng-template>
