import { Apollo } from 'apollo-angular';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalHostDirective } from '@app/core/directives/modal-host.directive';
import { ModalService } from '@app/core/services/modal/modal.service';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/browser';
import {
  UserGQL,
  User,
  Company,
  OnAcceptLineItemsGQL,
  NewOrderGQL,
  OnWontPackLineItemsGQL,
  OnRevertLineItemsToCreatedGQL,
  OnTemplateOutputStateChangeGQL,
  OnAddendumHeaderStateChangeGQL,
  OnReportCompleteGQL,
  OnPalletOutOutputStateChangeGQL,
  OnPalletInOutputStateChangeGQL,
  OnShipmentStateChangeGQL,
  OnAccountStatementStateChangeGQL,
} from '@app/generated/graphql';
import { CertificateHelper } from '@app/core/helpers/certificate.helper';
import { UserHelper } from '@app/core/helpers/user.helper';
import { UrlTrackerService } from '@app/core/services/url-tracker/url-tracker.service';
import { Router, NavigationEnd } from '@angular/router';

import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';

import { EventBusService } from '@app/core/services/event-bus/event-bus.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { LocalizeJsService } from '@app/app/core/services/localize-js.service';
import { StatementGenerationService } from './payments/statement-generation.service';

@Component({
  selector: 'ag-account',
  templateUrl: './account.component.html',
})
export class AccountComponent implements OnInit, OnDestroy {
  // subscription cleanup
  private destroy$ = new Subject<void>();

  @ViewChild(ModalHostDirective, { static: true })
  modalHost: ModalHostDirective;

  myCompany: Company;
  user: User;
  initialized = false;

  isExistingUser: boolean = null;
  isAgrigateUser: boolean = null;

  urlsWithoutPadding: string[] = [
    'account/financial-invoices/',
    'account/commercial-invoices/',
    'account/vendor-invoices/',
    'account/payments/payment-allocation/',
    'account/default-costs/default-cost-manager/',
  ];
  hasPadding = true;

  constructor(
    private modalService: ModalService,
    private userGql: UserGQL,
    private urlTrackerService: UrlTrackerService,
    private router: Router,
    private location: Location,
    private newOrderGql: NewOrderGQL,
    private apollo: Apollo,
    private onAcceptLineItemsGql: OnAcceptLineItemsGQL,
    private onWontPackLineItemsGql: OnWontPackLineItemsGQL,
    private onRevertLineItemsToCreatedGql: OnRevertLineItemsToCreatedGQL,
    private angulartics2Mixpanel: Angulartics2Mixpanel,
    private onTemplateChangeGql: OnTemplateOutputStateChangeGQL,
    private onAddendumHeaderStateChangeGql: OnAddendumHeaderStateChangeGQL,
    private onPalletOutOutputStateChangeGql: OnPalletOutOutputStateChangeGQL,
    private onPalletInOutputStateChangeGql: OnPalletInOutputStateChangeGQL,
    private onReportCompleteGQL: OnReportCompleteGQL,
    private onShipmentStateChangeGQL: OnShipmentStateChangeGQL,
    private eventBus: EventBusService,
    private localizeJsService: LocalizeJsService,
    private onAccountStatementStateChangeGQL: OnAccountStatementStateChangeGQL,
    private statementGenerationService: StatementGenerationService
  ) {}

  ngOnInit(): void {
    // Initial check for the current route
    this.checkRouteForPadding(this.location.path());

    // Subscribe to future navigation events
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        const currentRoute: string = event.urlAfterRedirects;
        this.checkRouteForPadding(currentRoute);
      });

    this.modalService.setHost(this.modalHost);

    this.userGql.fetch().subscribe(
      (gqlResponse) => {
        this.myCompany = gqlResponse.data.myCompany;
        this.user = gqlResponse.data.myUser;
        this.isExistingUser = UserHelper.isExistingUser(this.user);
        this.isAgrigateUser = UserHelper.isAgrigateUser(this.user);

        this.mixpanelTrackingSetup();

        Sentry.configureScope((scope) => {
          scope.setUser({
            id: this.user.id.toString(),
            email: this.user.email,
          });
        });

        this.urlTrackerService.trackPreviousUrl();

        this.initialized = true;
      },
      (err) => {
        Sentry.captureException(err);
        this.router.navigate(['account', '500'], { replaceUrl: true });
      }
    );

    this.userGql.watch().valueChanges.subscribe((gqlResponse) => {
      this.user = gqlResponse.data.myUser;
      this.myCompany = gqlResponse.data.myCompany;

      this.isExistingUser = UserHelper.isExistingUser(this.user);
    });

    this.newOrderGql.subscribe().subscribe((_) => {
      this.apollo.getClient().clearStore();
    });

    this.onAcceptLineItemsGql.subscribe().subscribe((res) => {
      this.apollo.getClient().clearStore();
    });

    this.onWontPackLineItemsGql.subscribe().subscribe((res) => {
      this.apollo.getClient().clearStore();
    });

    this.onRevertLineItemsToCreatedGql.subscribe().subscribe((res) => {
      this.apollo.getClient().clearStore();
    });

    // call the localization service for webapp language translation
    this.localizeJsService.initLocalizeJS();

    this.initTemplateSubscription();
    this.initPPECBAddendumStateSubscription();
    this.initReportSubscription();
    this.initPalletOutSubscription();
    this.initPalletInSubscription();
    this.initShipmentStateSubscription();
    this.initAccountStatementSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkRouteForPadding(url: string): void {
    const currentRoute = this.normalizeRoute(url);
    this.hasPadding = !this.urlsWithoutPadding.some((route) =>
      currentRoute.startsWith(route)
    );
  }

  private normalizeRoute(url: string): string {
    // Remove leading slash, query parameters, and hash
    return url.replace(/^\//, '').split(/[?#]/)[0];
  }

  mixpanelTrackingSetup() {
    if (!environment.angulartics2Enabled) return;

    // don't track events for agrigate users in production
    if (environment.production && this.isAgrigateUser) return;

    // set this to 'false' to enable frontend mixpanel tracking in development
    const skipDevTracking = true;
    if (environment.development && skipDevTracking) return;

    this.angulartics2Mixpanel.setUsername(this.user.externalIdentifier);
    this.angulartics2Mixpanel.setUserProperties({
      $first_name: this.user.firstName,
      $last_name: this.user.lastName,
      $email: this.user.email,
      $company_id: this.user.company.id,
      $role_name: this.user.role,
      $company_name: this.user.company.name,
    });
    this.angulartics2Mixpanel.startTracking();
  }

  initTemplateSubscription() {
    // templates are generated async on the backend, so we use a subscription to
    // dynamically update the link to a template document. A simple event bus
    // is used to tell interested components that they should fetch the data again.

    this.onTemplateChangeGql.subscribe().subscribe((res) => {
      const file = res?.data?.onTemplateOutputStateChange;

      if (!file) {
        return;
      }

      const type = file?.templateDataSourceType;
      const id = file?.templateDataSourceId;
      switch (type) {
        case 'AccountStatement':
        case 'Invoice':
        case 'LoadOutInstruction':
        case 'AddendumHeader':
          this.refetch(id, type);
      }
    });
  }

  initPPECBAddendumStateSubscription() {
    // addendums are generated async on the backend, so we use a subscription to
    // dynamically update the state of an addendum. A simple event bus
    // is used to tell interested components that they should fetch the data again.
    this.onAddendumHeaderStateChangeGql.subscribe().subscribe((res) => {
      const addendumHeader = res?.data?.onAddendumHeaderStateChange;
      const id = addendumHeader?.id;

      this.refetch(id, 'AddendumHeader');
    });
  }

  /**
   * An export notification is being done for the entire shipment,
   * multiple api calls have to be made many different records. We fetch
   * the shipment after it has completed with it's updated state and data.
   */
  initShipmentStateSubscription(): void {
    this.onShipmentStateChangeGQL.subscribe().subscribe((res) => {
      const shipment = res?.data?.onShipmentStateChange;
      const id = shipment?.id;

      this.refetch(id, 'Shipment');
    });
  }

  initPalletOutSubscription() {
    // pallet outs are generated async on the backend, so we use a subscription to
    // dynamically update the state of a pallet out. A simple event bus
    // is used to tell interested components that they should refetch the data.
    // in this case we want to refetch the addendum header, since the pallet out
    // is not a top level entity.
    this.onPalletOutOutputStateChangeGql.subscribe().subscribe((res) => {
      const palletOut = res?.data?.onPalletOutOutputStateChange;
      const parentId = palletOut?.addendumHeaderId;

      this.refetch(parentId, 'AddendumHeader');
    });
  }

  initPalletInSubscription() {
    // pallet ins are generated async on the backend, so we use a subscription to
    // dynamically update the state of a pallet in. A simple event bus
    // is used to tell interested components that they should refetch the data.
    // in this case we want to refetch the loi, since the pallet in
    // is not a top level entity.
    this.onPalletInOutputStateChangeGql.subscribe().subscribe((res) => {
      const palletIn = res?.data?.onPalletInOutputStateChange;
      const parentId = palletIn?.loadOutInstructionId;

      this.refetch(parentId, 'LoadOutInstruction');
    });
  }

  initReportSubscription() {
    this.onReportCompleteGQL.subscribe().subscribe((res) => {
      const id = res?.data?.onReportComplete.requestUuid;
      this.refetch(id, 'Report');
    });
  }

  initAccountStatementSubscription() {
    this.onAccountStatementStateChangeGQL.subscribe().subscribe((res) => {
      const accountStatement = res?.data?.onAccountStatementStateChange;
      const id = accountStatement?.id;

      if (accountStatement?.templateOutput?.state === 'completed') {
        this.statementGenerationService.completeGeneration();
        window.open(accountStatement.templateOutput.outputFiles[0].v2Url);
      }
      this.refetch(id, 'AccountStatement');
    });
  }

  refetch(id: string, type: string) {
    this.eventBus.setChannel(`Refetch${type}`, id);
  }
}
